.v4v h2 {
    font-size: 4em;
    font-weight: bold;
    padding-top: 1em;
    padding-bottom: .5em;
}

.v4v h4 {
    font-size: 2em;
    font-family: gastromond, sans-serif;
}

.v4v p {
    font-family: de-walpergens-pica, sans-serif;
    font-size: 1.3em;
    text-align: left;
    text-indent: 3em;
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
}

#noStyle {
    -webkit-appearance: none !important;
    text-decoration: underline;
    outline: inherit;
    border: none;
    background: none;
    padding: 0;
}
@media only screen and (max-width: 768px) {
    .v4v h2{
        font-size: unset;
    }
    .v4v p {
        text-indent: 5vw;
        font-size: 1em;
    }
}