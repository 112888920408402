.notFound {
    text-align: center;
    position: relative;
    /* We position this as relative to move the h1 to the desired location on the screen, using position:absolute. So this position:relative gives boundaries to any element being positioned as absolute that is nested inside of this .notFound. */
}

.notFound img {
    width: 100%;
    max-width: 1800px;
    display: block;
    margin: auto;
    /* Images should be resized and optimized. */
}

@media screeen and (min-width: 1200px) {
    .notFound {
        margin-top: -10em;
    }
}

.notFound h1 {
    position: absolute;
    padding: 5px .5em;
    font-size: 7vw;
    background: rgba(0,0,0,.5);
    border-radius: 25px;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -85%);
    color: white;
    width: fit-content;
    font-variant: small-caps;
}
.notFound p {
    position: absolute;
    font-size: 3.2vw;
    padding: 5px 1em;
    bottom: 10%;
    right: 30%;
    transform: translate(50%, -90%);
    color: white;
    background: rgba(0,0,0,.5);
    border-radius: 25px;
    width: fit-content;
    font-variant: small-caps;
}

.notFound a {
    position: absolute;
    font-size: 2.5vw;
    bottom: -5%;
    left: 50%;
    transform: translate(-50%, -100%);
}