.listen h1 {
    font-size: 4em;
    font-weight: bold;
    padding-top: .3em;
    padding-bottom: .5em;
}
.listen h2 {
    font-family: gin, serif;
    font-size: 5.5em;
    padding: 0;
}

.listen h4 {
    font-size: 1.7em;
    font-family: gastromond, sans-serif;
}
.listen a {
    font-family: de-walpergens-pica, sans-serif;
    font-size: 2em;
    text-decoration: none;
}
.listen a:hover {
    text-decoration: underline;
}
.listen .accordion-button {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
}
.listen .accordion-header {
    padding: 0;
}
.listen p {
    font-family: de-walpergens-pica, sans-serif;
    font-size: 1.3em;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
}
.listen img {
    width: 5em;
}

@media only screen and (max-width: 768px) {
    .listen h1{
        font-size: unset;
    }
    .listen p {
        text-indent: 5vw;
        font-size: 1em;
    }
}