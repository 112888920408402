.home h1 {
    font-family: sheila, sans-serif;
    font-weight: 700;
    font-size: 16vw;
    padding-top: 2vh;
    padding-bottom: 4vh;
    margin-bottom: 3vh;
 }

.home h2 {
    font-size: 6vw;
}

.home h4 {
    font-family: corner-store-jf, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3vw;
    padding-bottom: 3vh;
}
.home img {
    max-width: 100%;
    margin-top: -3em;
}
@media only screen and (max-width: 768px) {
    .home h1 {
        font-size: 19vw;
    }
}
@media only screen and (min-width: 1367px) {
    .home h1 {
        font-size: 10vw;
    }
    .home img {
        margin-top: -5em;
    }
}