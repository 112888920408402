.about h2{
    font-family: gastromond, sans-serif;
    font-size: 7vw;
    margin-bottom: .4em;
    padding-top: .7em;
}
.about img {
  min-width: none;
  max-width: inherit;
}
.about p {
    margin-top: auto;
    margin-bottom: 0;
    text-align: left;
    text-indent: 2.5em;
}
.band-bios {
    padding: 0 1em !important;
}

@media only screen and (max-width: 767px) {
    #flex {
        display: flex;
        flex-direction: column;
    }
    #SpencerBio{
        order: 2;
    }
    #SpencerImg{
        order: 1;
    }
    .about h2 {
        font-size: 10vw;
    }
    .about img {
        padding: 0 1em;
        margin: 0;
        width: 60%;
    }
    .about p {
        margin: 0;
    }
    .about h4 {
        padding: .5em 0;
    }
}