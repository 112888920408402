#albumContainer {
    padding-top: .5em;
    max-width: 1050px;
}
.carousel-item {
    max-height: 75vh !important;
    vertical-align: middle;
}
.carousel-item img {
    vertical-align: middle;
}
.carousel-caption {
    background-image: radial-gradient(circle at 50% 50%, rgba(200, 200, 200, .85), transparent);
    color: #000 !important;
}
.carousel-caption h3 {
    font-size: 3em;
    font-family: gastromond, serif;
}
.carousel-caption p {
    font-size: 1.5em;
    font-family: de-walpergens-pica, sans-serif;
}
.carousel-caption a {
    text-decoration: none !important;
    font-weight: bolder;
}
.carousel-caption a:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width: 768px){
    #albumContainer {
        padding: 0vh 2vw 2vh;
        height: 70vh !important;
    }
    .carousel-item {
        height: unset;
        /* unset is the way in which we can programatically undo previous stylings that have been set. */
    }
    .carousel-caption {
        padding: .8em 0 0 !important;
    }
    .carousel-caption h3 {
        font-size: 5vw;
        padding: 0;
    }
    .carousel-caption p {
        font-size: 4vw;
    }
}

@media only screen and (min-width: 465px) and (max-width: 504px) {
    #albumContainer {
        padding: 0vh 2vw 2vh;
    }
}

@media only screen and (max-width: 465px) {
    #albumContainer {
        padding: 4vh 2vw 2vh;
    }
}