.bump-me{
    height: 13.5vh;
}

@media only screen and (min-width: 258px) and (max-width: 438px) {
    .bump-me{
        height: 8vh;
    }
}
@media only screen and (min-width: 213px) and (max-width: 257px) {
    .bump-me{
        height: 5vh;
    }
}
@media only screen and (max-width: 212px) {
    .bump-me{
        height: 8vh;
    }
}