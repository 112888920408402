/* .navbar {
	height: 12.5vh;
} */

.navbar-brand .able {
	font-family: juniper-std, sans-serif;
	font-size: 2.6em !important;
}
.navbar-brand .and-the {
	font-family: hwt-catchwords, sans-serif;
	font-size: 3em !important;
}
.navbar-brand .wolf {
	font-family: juniper-std, sans-serif;
	font-size: 2.6em !important;
}
.navbar-brand img {
	max-width: 5rem;
	vertical-align: top;
	margin-right: 1em;
}
/* .navbar-brand img {
	max-height: 5rem;
	padding-left: 1.2em;
} */
.footer {
	vertical-align: middle;
	margin-top: 3em;
	padding-top: 2vh !important;
	padding-bottom: 2vh !important;
}
.footer p {
	font-size: 1.2em;
}
@media only screen and (max-width: 768px) and (min-width: 652px) {
	.navbar-brand{
		padding: 0 !important;
	} 
	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}
@media only screen and (min-width: 510px) and (max-width: 651px){
	.navbar {
		padding: 0 !important;
	}
    .navbar-brand {
        height: unset;
		font-size: 4vw !important;
        /* unset is the way in which we can programatically undo previous stylings that have been set. */
    }
	.navbar-brand img {
		max-width: 14vw;
		padding-top: .3em;
	}
	.footer p {
		font-size: 4vw;	
	}
	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}
@media only screen and (max-width: 510px){
	.navbar-brand {
		font-size: 3vw !important;
		align-items: center;
		display: flex;
	}
	.navbar-brand img {
		max-width: 12vw;
	}
}