@import url("https://use.typekit.net/rko5jpc.css");

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.btn-color,
button.btn-color {
    background-color: #0013E1;
    color: white;
    padding: .5em 2em;
    font-size: 1.2em;
    font-weight: bold;
    font-variant: small-caps;
    transition: .5s ease;
}

a.btn-color:hover,
.btn-color:hover {
    background-image: none;
    background-color: #0C95ED;
    color: white;
}